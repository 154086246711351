.modalContainer {
  position: absolute !important;
  max-width: 500px !important;
  background-color: white !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2) !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  outline: none !important;
}
.modalHeader {
  padding: 20px !important;
  display: flex !important;
  border-bottom: 1px solid #dee2e6 !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.modalBody {
  padding: 16px !important;
  border-bottom: 1px solid #dee2e6 !important;
}
