.MuiPaper-elevation4 {
  background-color: #f78b44 !important;
}
css-iszjx6 {
  background-color: #f78b44 !important;
}
.RaSidebar-fixed {
  background-color: #f78b444d !important;
  position: fixed !important;
  height: 100% !important;
}
.RaMenuItemLink-active {
  font-weight: 700 !important;
  font-size: 16px !important;
}
.css-8czsxw-MuiToolbar-root-RaTopToolbar-root {
  justify-content: flex-start !important;
}
.MuiToolbar-root.MuiToolbar-dense.css-mfydvp {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  display: inline-block !important;
}
.column-address {
  min-width: 400px !important;
}
.column-dishName {
  min-width: 200px !important;
}
.column-spiceLevel {
  min-width: 200px !important;
}
.column-city {
  min-width: 200px !important;
}
.RaList-actions {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  display: inline-block !important;
}
.MuiTablePagination-root {
  display: -webkit-box;
}
.css-rqglhn-MuiTable-root {
  border-collapse: separate !important;
}
.RaList-content {
  margin-right: 20px !important;
}
body {
  margin: 0px !important;
}
.css-14mup8f-RaDatagrid-root .RaDatagrid-headerCell:first-of-type {
  border-top-left-radius: 0px !important;
}
.css-14mup8f-RaDatagrid-root .RaDatagrid-headerCell:last-child {
  border-top-right-radius: 0px !important;
}
.MuiTableCell-sizeSmall {
  border: 1px solid !important;
}
table {
  border: 0.5px solid !important;
}
th {
  font-weight: 700 !important;
  border: 0.5px solid !important;
}
/* .RaAppBar-menuButton {
  display: none !important;
} */
.RaAppBar-title {
  padding-left: 10px !important;
}
.css-1o6fzn1 {
  border: 1px solid !important;
}
/* .column-discription {
  min-width: 400px !important;
} */
.css-16w5hsz-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root
  .RaMenuItemLink-icon {
  padding-top: 5px !important;
}
.RaList-main {
  width: 100% !important;
  position: absolute !important;
  overflow-x: scroll !important;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.truncate-text:hover {
  max-width: 400px;
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
}

.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
}

.form-input {
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: #fff;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
