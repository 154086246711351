.modalContainer {
  position: absolute !important;
  max-width: 500px !important;
  background-color: white !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2) !important;
  /* padding: 16px !important; */
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  outline: none !important;
}
.modal-checkbox {
  max-height: 100px !important;
  overflow-y: auto !important;
}

.css-1n6sfyn-MenuList {
  max-height: 150px !important;
  overflow-y: auto !important;
}
.modalHeader {
  padding: 20px !important;
  display: flex !important;
  border-bottom: 1px solid #dee2e6 !important;
  justify-content: space-between !important;
  align-items: center !important;
  p {
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #212529 !important;
  }
}
.modalBody {
  padding: 16px !important;
  border-bottom: 1px solid #dee2e6 !important;
  p {
    color: #212529 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
}
.modalFooter {
  padding: 16px !important;

  .buttonSection {
    display: flex;
    justify-content: flex-end;
  }

  .errorTxt {
    margin-bottom: 16px;
  }
}
.cancelButton {
  margin-right: 16px !important;
  padding: 6px 12px 6px 12px !important;
  color: white !important;
  border-radius: 4px !important;
  background: #6c757d !important;
}
.activateButton {
  padding: 6px 12px 6px 12px !important;
  color: white !important;
  border-radius: 4px !important;
  background: #f78b44 !important;
}
